
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.root {
  display: flex;
}

body{
  background-color: #FBFBFB;
  font-family: "Quicksand", sans-serif;;
}
.hidden-for-print {
  visibility: hidden; /* Hide on the webpage */
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
@media print {
  .hidden-for-print {
    visibility: visible; /* Show on print (PDF) */
  }
}
canvas {
  width: 100% !important;
  height: 100% !important;
}