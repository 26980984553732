
  ::-webkit-scrollbar-thumb {
    background-color: #033d44; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Border radius of the scrollbar thumb */
    margin-left: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the scrollbar track */
  }
  