
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-trademark{
  border-radius: 50%;
  cursor: pointer;
}
.video-not-played{
  border:5px solid #033d44
}
.video-played{
  border:5px solid #aebac1
}
.video-box{
  background-color: black;
  height: 50vh;
}
.video-user{
  height: 50vh;
  width: 100%;
}
.play-btn{
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50%;
}
.video-story{
  border-radius: 25px;
  padding: 15px;
  width: 50%;
}
.spinner-section {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}